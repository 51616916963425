import axios from 'axios';

window.axios = axios;

export const HTTP = axios.create({
    headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'X-Requested-With': 'XMLHttpRequest'
    }
});

export const HTTP_API = axios.create({
    withCredentials: false,
    baseURL: window.baseUrlApi,
    headers: {
        'Content-Type': 'application/json',
        'X-Accept-Version': 'v1.3'
    }
});

export const route = ({ name, params = {}, absolute = false }) => {
    if (!window.Routing) {
        throw new Error('No rooting dependency')
    }

    return window.Routing.generate.apply(window.Routing, [name, params, absolute]);
};