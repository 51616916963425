<template>
    <v-select
        :id="id"
        v-model="selectLang"
        :items="items"
        item-title="text"
        height="25px"
        :hide-details="true"
        :label="label"
        :class="{ notFullWidth: !fullWidth }"
        density="compact"
        variant="solo"
        class="public-field"
        @change="$emit('change', $event)"
    >
        <template #selection="{ item }">
            <span class="text-truncate mr-1">{{ item.title }}</span>
            <dx-flag
                size="small"
                :lang="item.value"
            />
        </template>
        <template #item="{ item }">
            <v-list-item
                tag="button"
                :value="item.value"
                @click="$emit('change', item.value)"
            >
                <span class="text-truncate mr-1">{{ item.title }}</span>
                <dx-flag
                    size="small"
                    :lang="item.value"
                />
            </v-list-item>
        </template>
    </v-select>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DxFlag from './DxFlag.vue';

export default defineComponent({
    name: 'DxSelectLanguage',
    components: {
        DxFlag
    },
    props: {
        id: {
            type: String,
            default: ''
        },
        items: {
            type: Array,
            default: () => []
        },
        currentLocale: {
            type: String,
            default: 'en'
        },
        label: {
            type: String,
            default: ''
        },
        fullWidth: {
            type: Boolean,
            default: true
        }
    },
    data: () => ({
        selectLang: ''
    }),
    watch: {
        currentLocale() {
            this.setDefaultSelectLang();
        }
    },
    created() {
        this.setDefaultSelectLang();
    },
    mounted() {
        this.$el.querySelector('input').setAttribute('data-type', 'vSelect')
    },
    methods: {
        setDefaultSelectLang() :void {
            if (this.currentLocale) {
                this.selectLang = this.currentLocale;
            }
        }
    }
})
</script>

<style lang="scss" scoped>
.v-select {
    &.notFullWidth {
        max-width: 180px;
    }
}

.v-list-item {
    width: 100%;
}

:deep() {

    .v-field--active .v-label.v-field-label {
        visibility: visible;
        margin-left: 16px;
    }

    .v-field-label--floating {
        display: none;
    }

    .v-list-item__content {
        display: flex;
        justify-content: space-between;
    }

    .v-select__selection {
        justify-content: space-between;
        min-width: 100px;
        input {
            position: absolute;
            z-index: -1;
        }
        .flag.f-ar {
            position: relative;
            left: 20px;
            margin-left: 0;
        }
    }
    .v-select__selection {
        overflow: hidden;
        height: 18px;
        font-size: 12px;
        font-weight: normal;
        padding-right: 0.5em;
    }
    .v-list {
        &:before, &:after {
            display: none;
        }
    }
}
</style>