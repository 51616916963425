function PictbaseUser(user) {
    jQuery.extend(this, user);
}

window.PictbaseUser = PictbaseUser;

PictbaseUser._currentUser = null;

PictbaseUser.setCurrentUser = function(user) {
    PictbaseUser._currentUser = new PictbaseUser(user);
};

PictbaseUser.getCurrentUser = function() {
    return PictbaseUser._currentUser;
};

PictbaseUser.prototype.getId = function() {
    return this.id;
};

PictbaseUser.prototype.hasPermission = function (name) {
    return this.permissions[name] === true;
};