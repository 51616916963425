import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dx_select_language = _resolveComponent("dx-select-language")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!

  return (_openBlock(), _createElementBlock("main", null, [
    _createVNode(_component_v_card, {
      variant: "flat",
      hidden: !_ctx.isLoaded,
      loading: _ctx.isLoading,
      class: "pa-sm-6 pa-md-8"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, { class: "justify-space-between d-flex pa-0 pb-4" }, {
          default: _withCtx(() => [
            _createVNode(_component_dx_select_language, {
              id: "cgu-lang",
              items: _ctx.availableLocales,
              "current-locale": _ctx.currentLocale,
              "full-width": false,
              onChange: _ctx.changeLocale
            }, null, 8 /* PROPS */, ["items", "current-locale", "onChange"]),
            (_ctx.$route.name === 'cgu')
              ? (_openBlock(), _createBlock(_component_v_btn, {
                  key: 0,
                  icon: "fa-times",
                  size: "small",
                  to: { name:'login' }
                }))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_v_card_text, {
          class: _normalizeClass({ 'cgu-validate': _ctx.isCguValidate }),
          innerHTML: _ctx.cgu
        }, null, 8 /* PROPS */, ["class", "innerHTML"]),
        (_ctx.isCguValidate)
          ? (_openBlock(), _createBlock(_component_v_card_actions, {
              key: 0,
              class: "justify-end"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  to: { name:'login' },
                  color: "neutral",
                  variant: "flat"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('GENERIC.ACTIONS.REFUSE')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_btn, {
                  id: "btnAcceptCgu",
                  href: "/cgu/accept",
                  color: "secondary",
                  variant: "flat"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('GENERIC.ACTIONS.ACCEPT')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["hidden", "loading"]),
    _createVNode(_component_v_progress_circular, {
      hidden: _ctx.isLoaded,
      size: 100,
      class: "loader-primary",
      indeterminate: ""
    }, null, 8 /* PROPS */, ["hidden"])
  ]))
}