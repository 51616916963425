<template>
    <v-card
        rounded
        max-width="430"
    >
        <v-card-title class="px-sm-8 pt-sm-8 px-md-12 pt-md-12 pb-12 d-flex flex-column justify-center">
            <h1
                class="text-h4 font-weight-bold"
                v-html="title"
            ></h1>
            <p
                class="font-weight-normal mb-0 px-6"
                v-html="subtitle"
            />
        </v-card-title>
        <v-card-text class="px-sm-12 pb-10 px-xs-8">
            <slot name="body" />
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'DxLoginPassLayout',
    props: {
        title: {
            type: String,
            required: true
        },
        subtitle: {
            type: String,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
:deep() {
    .v-card-title {
        line-height: 1.8em;
        word-break: normal;
        white-space: normal;
        text-align: center;

        h1 {
            margin-bottom: 0.3em;
            strong {
                color: rgba(var(--v-theme-secondary));
            }
        }

        p {
            font-size: 14px !important;
            text-align: center !important;
            line-height: 1.4em;
        }
    }

    .v-btn {
        font-size: 14px !important;
    }
}

</style>