<template>
    <div class="content">
        <v-progress-circular
            indeterminate
            :size="50"
            color="info"
            class="loadingElVue"
        />
    </div>
</template>

<style lang="scss" scoped>
    .content {
        margin: 10px;
        min-height: inherit;
        min-width: inherit;
        height: inherit;
        width: inherit;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
</style>