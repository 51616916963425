/* globals shortcuts */
import { getLang } from "../../Vue/shared/utils.vue";

export const initStore = ({ dispatch, commit }) => {
    if (typeof shortcuts !== 'undefined') {
        dispatch('loadShortcutsList');
    }

    commit('SET_LANG', getLang());
};

export const loadShortcutsList = ({ commit }) => {
    commit('SET_SHORCUTS', shortcuts);
};