<template>
    <dx-login-pass-layout
        :title="$t('LOGIN.WELCOME_TITLE', { brand: whiteLabel.brand_name })"
        :subtitle="$t('LOGIN.LOGIN_TEXT')"
    >
        <template v-slot:body>
            <v-form
                id="form"
                ref="form"
                action="/login_check"
                method="POST"
                @submit.prevent="processForm"
            >
                <dx-global-form-alert
                    v-if="displayLoginError || displayInfo"
                    :message="displayLoginError ? error : info"
                    :type="displayLoginError ? 'error' : 'success'"
                />

                <div v-show="hidePassword">
                    <v-text-field
                        id="username"
                        v-model="username"
                        name="_username"
                        :label="$t('GENERIC.FORM.LABELS.EMAIL')"
                        variant="underlined"
                        class="public-field"
                        @keyup="hideAlerts"
                        @blur="v$.username.$touch()"
                        :error="v$.username.$error || displayLoginError"
                        :error-messages="userNameErrors"
                    />
                </div>
                <div
                    v-if="!hidePassword"
                    class="d-flex justify-center"
                >
                    <v-btn
                        :title="$t('GENERIC.FORM.ACTIONS.EDIT')"
                        class="back text-lowercase mb-2"
                        :ripple="false"
                        variant="outlined"
                        size="small"
                        prepend-icon="fa-arrow-left"
                        @click="hidePassword = !hidePassword, displayInfo = false, displayLoginError = false"
                    >
                        {{ username }}
                    </v-btn>
                </div>

                <v-text-field
                    v-if="!hidePassword"
                    id="password"
                    variant="underlined"
                    v-model="password"
                    :hidden="hidePassword"
                    type="password"
                    name="_password"
                    :label="$t('GENERIC.FORM.LABELS.PASSWORD')"
                    class="public-field"
                    required
                    :error-messages="passwordErrors"
                    :error="v$.password.$error || displayLoginError"
                    @blur="v$.password.$touch()"
                    @keyup="hideAlerts"
                />

                <router-link
                    class="float-right"
                    :to="{ name:'password-reset-request', params: { emailForgotPassWord: emailForgotPassWord } }"
                >
                    {{ $t('GENERIC.FORM.LABELS.FORGOT_PASSWORD') }}
                </router-link>

                <v-btn
                    class="mt-13 text-uppercase"
                    type="submit"
                    :disabled="loading"
                    :loading="loading"
                    depressed
                    block
                    color="secondary"
                    @click="processForm"
                >
                    {{
                        hidePassword ? $t('GENERIC.ACTIONS.NEXT') : $t('GENERIC.ACTIONS.CONNECT')
                    }}
                </v-btn>
            </v-form>
        </template>
    </dx-login-pass-layout>
</template>

<script lang="ts">

import useVuelidate from '@vuelidate/core';
import DxLoginPassLayout from '@/components/Forms/Login/DxLoginPassLayout.vue';
import DxGlobalFormAlert from '@@/shared/components/forms/DxGlobalFormAlert.vue';
import { HTTP } from '../../../../Vue/http-common';
import { required, helpers, requiredIf, email } from '@vuelidate/validators';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'DxLoginForm',
    inject: ['whiteLabel'],
    setup () {
        return { v$: useVuelidate() }
    },
    components: {
        DxLoginPassLayout,
        DxGlobalFormAlert
    },
    props: {
        error: {
            type: String,
            required: true
        },
        info: {
            type: String,
            required: true
        },
        lastUsername: {
            type: String,
            required: true
        }
    },
    data: () => ({
        loading: false,
        hidePassword: true,
        displayLoginError: false,
        displayInfo: false,
        password: '',
        username: '',
    }),
    created() {
        if (localStorage.getItem('username')) {
            this.username = localStorage.getItem('username');
        } else {
            this.username = this.lastUsername;
        }
        this.displayLoginError = this.error !== '';
        this.displayInfo = this.info !== '';
        if (this.displayLoginError) {
            this.hidePassword = false;
        }
    },
    watch: {
        username(value) {
            this.$store.commit('SET_LOGIN_EMAIL', value);
        }
    },
    updated() {
        document.getElementById(this.hidePassword ? 'username' : 'password').focus();
    },
    validations() {
        return {
            username: {
                required: helpers.withMessage('GENERIC.FORM.ERRORS.REQUIRED', required),
                email: helpers.withMessage('GENERIC.FORM.ERRORS.EMAIL', email)
            },
            password: {
                requiredIfFoo: helpers.withMessage('GENERIC.FORM.ERRORS.REQUIRED', requiredIf(!this.hidePassword))
            }
        }
    },
    methods: {
        hideAlerts() {
            this.displayLoginError = false;
            this.displayInfo = false;
        },
        processForm(evt) {
            this.v$.$validate().then(valid => {

                if (!valid) {
                  evt.preventDefault();
                }

                if (valid) {
                    this.loading = true;
                    if (this.hidePassword) {
                        evt.preventDefault();
                        // SEEK FOR LOGIN METHOD (PASSWORD OR SSO
                        HTTP.post('/login_method', {username: this.username})
                            .then(response => {
                                const {data} = response;
                                const {method} = data;
                                if (localStorage.getItem('username') !== this.username) {
                                    localStorage.setItem('username', this.username)
                                }
                                if (method === 'sso') {
                                    window.location = data.url_sso;
                                } else {
                                    this.hidePassword = false;
                                    this.loading = false;
                                }
                            })
                            .catch(() => {
                                this.loading = false;
                            });
                    } else {
                        document.getElementById('form').submit();
                    }
                }
            });
        }
  },
  computed: {
    userNameErrors() {
      return (this.v$.username.$errors.length) ? this.$t(this.v$.username.$errors[0].$message) : [];
    },
    passwordErrors() {
      return (this.v$.password.$errors.length) ? this.$t(this.v$.password.$errors[0].$message) : [];
    }
  }
})
</script>

<style lang="scss" scoped>

    :deep(.v-form) {
        .back {
            padding: 0 6px !important;
            opacity: 0.4;
            color: #191919;
            border-color: #191919;

            &:hover {
              opacity: 1;
            }

            .v-icon {
                font-size: 14px;
                margin-right: 0.5em;
            }
        }
    }

    .link-forgot-pw {
        float: right;
        @at-root html[dir="rtl"] & {
            float: left;
        }
    }

</style>
