<template>
    <country-flag
        :size="size"
        :lang="lang"
        :country="country(lang)"
    />
</template>

<script setup lang="ts">
import CountryFlag from 'vue-country-flag-next';

const props = defineProps({
    size: {
        type: String,
        default: ''
    },
    lang: {
        type: String,
        default: 'en'
    }
})

const country = (value: string) => {
    if (value === 'en') {
        return 'gb';
    }
    return value;
}

</script>

<style scoped lang="scss">
.flag {
    &.flag-ar, &.f-ar { //for Arabic language
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAMAAABBPP0LAAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwAAACiUExURQB6PQB5Owp/RAR8QAN8QAF6PmGshwB4OgBvLQB6PAF6PQN7PwB3OWCshnW3lgJ7PgBtK0efc1anfqDNtyOMVwB2NwF7PgB5PAN8PwB0NWiwjJfJsIG9n1GkewB2OAd+QhmHUAJ7PwBzM0mgdYnBpTyZayCLVgB1NiiPXABtKgBxMAB3OAZ9QZ3MtKTPupjJsTeXZwV9QQB4OSqQXQ6BSP///+cfvOIAAAABYktHRDXettlrAAAAB3RJTUUH5wcfCR02QsjYSQAAAG1JREFUCNdjYIABRgY0wMTMgsxlZWRj5+BEEuDi5uHl40eIsHIKCAoJi4iKgXniDBKSUtLCMrJyUL68gqKSsoqqmihEtZi6hrKmmKqclrYi1DhxHV09fQNRQ1aYgWJG/MYm6vxIVopxSrIrwjgA8m8HeaBjYagAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjMtMDctMzFUMDk6Mjg6NTIrMDA6MDCReWZ8AAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIzLTA3LTMxVDA5OjI4OjUyKzAwOjAw4CTewAAAACh0RVh0ZGF0ZTp0aW1lc3RhbXAAMjAyMy0wNy0zMVQwOToyOTo1NCswMDowMDsjoRsAAAAASUVORK5CYII=");
        background-position: center center !important;
        width: 70px;
        height: 50px;
        position: relative;
        left: 10px;
        background-size: cover;
    }
}
</style>