import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ad296e3a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-truncate mr-1" }
const _hoisted_2 = { class: "text-truncate mr-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dx_flag = _resolveComponent("dx-flag")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_select = _resolveComponent("v-select")!

  return (_openBlock(), _createBlock(_component_v_select, {
    id: _ctx.id,
    modelValue: _ctx.selectLang,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectLang) = $event)),
    items: _ctx.items,
    "item-title": "text",
    height: "25px",
    "hide-details": true,
    label: _ctx.label,
    class: _normalizeClass([{ notFullWidth: !_ctx.fullWidth }, "public-field"]),
    density: "compact",
    variant: "solo",
    onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('change', $event)))
  }, {
    selection: _withCtx(({ item }) => [
      _createElementVNode("span", _hoisted_1, _toDisplayString(item.title), 1 /* TEXT */),
      _createVNode(_component_dx_flag, {
        size: "small",
        lang: item.value
      }, null, 8 /* PROPS */, ["lang"])
    ]),
    item: _withCtx(({ item }) => [
      _createVNode(_component_v_list_item, {
        tag: "button",
        value: item.value,
        onClick: ($event: any) => (_ctx.$emit('change', item.value))
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_2, _toDisplayString(item.title), 1 /* TEXT */),
          _createVNode(_component_dx_flag, {
            size: "small",
            lang: item.value
          }, null, 8 /* PROPS */, ["lang"])
        ]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "onClick"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["id", "modelValue", "items", "label", "class"]))
}