import { HTTP } from '@/http-common';
import * as dayjs from 'dayjs';

export const isInFieldArray = function (itemName, fields, key) {
    for (const i in fields) {
        if (typeof fields[i][key] !== 'undefined' && fields[i][key] === itemName) return true;
    }
    return false;
};

export const getIndexInFieldArray = function (itemName, fields, key) {
    for (const i in fields) {
        if (typeof fields[i][key] !== 'undefined' && fields[i][key] === itemName) return i;
    }
    return -1;
};

export const isArray = function (elem) {
    if (typeof elem !== 'object') {
        return false;
    }

    return Array.isArray(elem);
};

export const isObject = function (elem) {
    if (typeof elem !== 'object') {
        return false;
    }

    return !Array.isArray(elem);
};

/*
* return object or value from this object
 */
export const getItemValueInArrayIndex = function (itemName, fields, searchKey, returnIndex) {
    const index = getIndexInFieldArray(itemName, fields, searchKey);
    if (index !== -1 && typeof fields[index][returnIndex] !== 'undefined') {
        return fields[index][returnIndex];
    }
    return null;
};

export const buildListUrl = function ({
    isRoot, listId, filter = null
}) {
    const params = {
        list_id: listId,
        max_depth: 'undefined',
        strict_depth: true
    };
    if (filter !== null) {
        params.filter = filter.trim();
    }
    return Routing.generate(isRoot ? 'pictbase_list_details_getroot' : 'pictbase_list_details_getitems', params, true);
};

export const getList = function ({
    isRoot, listId, id, maxDepth, filter = null
}) {
    return HTTP.post(`${buildListUrl({
        isRoot, listId, maxDepth, filter
    })}&id=${id}`, { id: listId });
};

export const getLang = () => document.getElementsByTagName('html')[0].getAttribute('lang');

export const getLangAndDefaultCountry = () => {
    const lang = getLang();
    const countryLang = {
        fr: 'fr-FR',
        en: 'en-Uk',
        ro: 'ro-RO',
        de: 'de-DE',
        es: 'es-ES',
        tr: 'tr-TR'
    }

    return countryLang[lang] ? countryLang[lang] : 'en-UK';
}

export const pluginUtils = {
    install: (app) => {
        app.mixin({
            beforeCreate() {
                this.$dxVueUtils = {
                    isArray,
                    getList,
                    buildListUrl,
                    getItemValueInArrayIndex,
                    isObject,
                    getIndexInFieldArray,
                    isInFieldArray,
                    getLang
                }
            }
        });
    }
}

export const isRtl = () => {
    const dir = document.querySelector('html').getAttribute('dir') ?? 'ltr';
    return dir === 'rtl';
}

export const toFormatDate = (timeStamp, lang) => {
    const locale = lang === 'en' ? 'en-UK' : lang;
    if (locale === 'ar') {
        return dayjs(timeStamp).format('YYYY/MM/DD')
    }

    return new Date(timeStamp).toLocaleDateString(locale, {
        year: 'numeric', month: 'numeric', day: 'numeric'
    })
};

export const toFormatDateTime = (timeStamp, lang) => {
    const locale = lang === 'en' ? 'en-UK' : lang;
    if (locale === 'ar') {
        return dayjs(timeStamp).format('a hh:mm:ss YYYY/MM/DD');
    }

    return new Date(timeStamp).toLocaleDateString(locale, {
        year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit'
    })
}

export const formatDateToTimestamp = (value) => new Date(value).valueOf() / 1000;
export const formatTimestampToDate = (value) => new Date(value * 1000);

export const isLocationValidObject = (location) => location.lng && location.lat;

export const isInDevFlag = () => {
    const { hostname } = window.location;
    const allowListHostname = ['feature.daxium-air.com', 'dev.daxium-air.com', 'refronte.daxium-air.com', 'tableau-scn-hub-digital.daxium-air.com', 'www.daxium-air.test', 'localhost'];

    return allowListHostname.includes(hostname);
}