<template>
    <v-alert
        :type="type"
        variant="outlined"
        :icon="showIcon ? displayIcon : false"
        class="mb-4 dx-alert"
        density="compact"
    >
        <slot>
            <span v-html="message" />
        </slot>
    </v-alert>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
    message: {
        type: String,
        default: ''
    },
    type: {
        type: String,
        default: 'info'
    },
    showIcon: {
        type: Boolean,
        default: true
    }
});

const displayIcon = computed(() => {
    switch (props.type) {
    case 'warning':
        return 'fa-warning';
    case 'error':
        return 'fa-times-circle';
    case 'info':
        return 'fa-circle-info';
    case 'success':
        return 'fa-check-circle';
    default:
        return 'fa-check-circle';
    }
});
</script>

<style lang="scss" scoped>
    .dx-alert {
        margin: 0;
        width: 100%;
        :deep() {
            &.v-alert {
                flex: initial;
                a {
                    color: inherit !important;
                    text-decoration: underline;
                    cursor: pointer;
                    &:hover {
                        text-decoration: none;
                    }
                }

                .v-alert__prepend {
                    margin-inline-end: 0;
                }
            }
        }
    }
</style>