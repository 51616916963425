/* globals vueAppManager */
import App from './AppVue2.vue';
import { HTTP, route } from '@/http-common';
import AppManager from '@/appManager';
import showBrowserUpdate from '../Vue/browser_update';
import ieLogin from './ie-login';

import '../Vue/assets/icons/daxium-icons.css';

if (localStorage.getItem('cookie:accepted')) {
    localStorage.removeItem('cookie:accepted');
    localStorage.setItem('vue-cookie-accept-decline-cookieBanner', 'accept')
}

window.vueAppManager = new AppManager(App);

document.addEventListener('DOMContentLoaded', () => {
    showBrowserUpdate();
    if (navigator.userAgent.indexOf('Trident') > -1) {
        ieLogin();
        return;
    }
    vueAppManager.domParse();
});

window.addEventListener('storage', (event) => {
    if (event.key === 'logout-event' && localStorage.getItem('logout-event') === 'after') {
        localStorage.removeItem('logout-event');
        window.location.href = '/';
    }
});

document.addEventListener('visibilitychange', () => {
    if (window.routingParams && !document.hidden && document.querySelector('.vueRouterInit')) {
        const url = route({ name: 'pictbase_user_connected' });
        HTTP.get(url)
            .then(response => {
                if (!response.data.session_exists) {
                    window.location.href = '/';
                }
            });
    }
});

window.addEventListener('error', e => {
    if (/Loading chunk [\d]+ failed/.test(e.message) && !sessionStorage.getItem('reloadChunk')) {
        const message = (!Translator && typeof Translator.trans !== 'function')
            ? 'A new version has been deployed. Need to reload the page to apply changes.' : Translator.trans('TK.COMMON.JS.ERROR.CHUNKLOAD.MESSAGE')

        sessionStorage.setItem('reloadChunk', 'done');
        if (window.confirm(message)) {
            window.location.reload();
        }
    }
});