<template>
    <main>
        <v-card
            variant="flat"
            :hidden="!isLoaded"
            :loading="isLoading"
            class="pa-sm-6 pa-md-8"
        >
            <v-card-title class="justify-space-between d-flex pa-0 pb-4">
                <dx-select-language
                    id="cgu-lang"
                    :items="availableLocales"
                    :current-locale="currentLocale"
                    :full-width="false"
                    @change="changeLocale"
                />

                <v-btn
                    v-if="$route.name === 'cgu'"
                    icon="fa-times"
                    size="small"
                    :to="{ name:'login' }"
                />
            </v-card-title>
            <v-card-text
                :class="{ 'cgu-validate': isCguValidate }"
                v-html="cgu"
            />
            <v-card-actions
                v-if="isCguValidate"
                class="justify-end"
            >
                <v-btn
                    :to="{ name:'login' }"
                    color="neutral"
                    variant="flat"
                >
                    {{ $t('GENERIC.ACTIONS.REFUSE') }}
                </v-btn>
                <v-btn
                    id="btnAcceptCgu"
                    href="/cgu/accept"
                    color="secondary"
                    variant="flat"
                >
                    {{ $t('GENERIC.ACTIONS.ACCEPT') }}
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-progress-circular
            :hidden="isLoaded"
            :size="100"
            class="loader-primary"
            indeterminate
        />
    </main>
</template>

<script lang="ts">
/* globals PictbaseUser */
import { defineComponent } from 'vue';
import DxSelectLanguage from '../../Vue/shared/components/DxSelectLanguage.vue';
import { getLang } from "../../Vue/shared/utils.vue";

export default defineComponent({
    name: 'Cgu',
    components: {
        DxSelectLanguage
    },
    data: () => ({
        isLoading: true,
        isLoaded: false,
        currentLocale: '' as string | null,
        cgu: '',
        availableLocales: []
    }),
    computed: {
        isCguValidate() :boolean {
            return this.$route.matched.some(({ name }) => name === 'cgu-validate');
        }
    },
    created() {
        if (!PictbaseUser.getCurrentUser()) {
            this.currentLocale = getLang();
        }
        this.getCgu();
    },
    methods: {
        getCgu() :void {
            let params = {};
            if (this.currentLocale) {
                params = { params: { locale: this.currentLocale } };
            }

            this.$http.get('/cgu/ajax', params)
                .then((response: any) => {
                    const { data } = response;
                    this.availableLocales = data.availableLocales;
                    this.currentLocale = data.locale;
                    this.cgu = data.cgu;
                    this.isLoaded = true;
                    this.isLoading = false;
                })
                .catch(() => {
                    this.isLoaded = true;
                    this.isLoading = false;
                });
        },
        changeLocale(locale: string) {
            this.currentLocale = locale;
            this.isLoading = true;
            this.getCgu()
        }
    }
})
</script>

<style lang="scss" scoped>
    main {
        width: 100%;
        max-width: 900px;
    }

    .loader-primary {
        color: map-get($color1, bg);
    }

    .v-btn {
        outline: none;
        &:before {
            display: none;
        }
    }

    :deep() {
        .v-card-text {
            overflow-y: auto;
            height: calc(100vh - 350px);
            line-height: 1.5;

            &.cgu-validate {
                height: calc(100vh - 450px);
                margin-bottom: 100px;
            }

            h1 {
                font-size: 1.5rem;
                font-width: normal;
                color: $color-scheme-1;
                line-height: 1;
                strong {
                    font-weight: normal;
                }

                u {
                    text-decoration: none;
                }

                &:first-of-type {
                    margin-bottom: 1.6em;
                    font-size: 2.5rem;
                }
            }

            h2, h2 strong {
                font-weight: normal;
                color: $color-scheme-1;
                font-size: 1.3rem;
            }
        }

        .v-btn {
            font-size: 14px !important;
            text-transform: uppercase !important;
        }
    }
</style>
