<template>
    <v-app :id="'dx' + Date.now()" :class="globalStyles">
        <component
            :is="componentInstance"
            v-if="init.componentName !== 'singlePage'"
            v-bind="currentProperties"
        />
        <router-view v-else />
        <dx-notications />
    </v-app>
</template>

<script lang="ts">
import { defineAsyncComponent, PropType, defineComponent } from 'vue'
import DxNotications from '@/shared/components/DxNotifications.vue';
import CookieBanner from '../Vue/views/CookieBanner.vue';

interface InitObject {
    componentName: string,
    props: {
        id?: string,
        storeName?: string;
        legacyVue?: boolean
    }
}

export default defineComponent({
    components: {
        DxNotications
    },
    props: {
        init: {
            type: Object as PropType<InitObject>,
            required: true
        }
    },
    computed: {
        // eslint-disable-next-line consistent-return,vue/return-in-computed-property
        componentInstance() {
            // eslint-disable-next-line default-case
            switch (this.init.componentName) {
            case 'publicPage':
                return defineAsyncComponent(() => import('@/views/Public.vue'));
            case 'cookieBanner':
                return CookieBanner;
            }
        },
        globalStyles() {
            return this.init.props && this.init.props.legacyVue ? 'legacyVueStyles' : 'defaultVueStyles';
        },
        currentProperties() {
            return this.init.props;
        }
    },
    created() {
        this.$store.dispatch('initStore');
    }
})
</script>

<style lang="scss">
    $color-pack: false;
    @import "@/scss/global/index.scss";

    a {
        color: $theme2;
        text-decoration: none;
    }

    [hidden] {
        display: none !important;
    }
</style>
