import { createWebHistory, createRouter } from 'vue-router';
import DxLoginForm from '@/components/Forms/Login/DxLoginForm.vue';
import Cgu from '@/views/Cgu.vue';
import DxPasswordResetRequest from '@/components/Forms/Login/DxPasswordResetRequest.vue';
import DxPasswordReset from '@/components/Forms/Login/DxPasswordReset.vue';
import DxPublicLinkDisplay from '@/components/PublicLinks/DxPublicLinkDisplay.vue';
import { loadLanguageAsync } from '@/locales';

const router = createRouter({
    history: createWebHistory('/'),
    routes: [
        {
            path: '/',
            name: 'login',
            component: DxLoginForm
        },
        {
            path: '/cgu',
            name: 'cgu',
            component: Cgu
        },
        {
            path: '/cgu/validate',
            name: 'cgu-validate',
            component: Cgu
        },
        {
            path: '/login-reset-password',
            name: 'password-reset-request',
            component: DxPasswordResetRequest
        },
        {
            path: '/login-reset-password/:id',
            name: 'password-reset',
            component: DxPasswordReset
        },
        {
            path: '/publiclinkconfirmed/:id',
            name: 'public-link-display',
            component: DxPublicLinkDisplay
        }
    ]
})

router.beforeEach((to, from, next) => {
    loadLanguageAsync().then(() => next());
});

export default router;