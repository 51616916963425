<template>
    <dx-login-pass-layout
        :title="$t('FORGOTTEN_PASSWORD.RESET_PASSWORD_TITLE')"
        :subtitle="$t('FORGOTTEN_PASSWORD.RESET_PASSWORD_TEXT')"
    >
        <template v-slot:body>
            <dx-global-form-alert
                v-if="resetError || success"
                :message="resetError ? resetError : info"
                :type="resetError ? 'error' : 'success'"
            />

            <v-btn
                v-if="success"
                class="text-uppercase mt-10"
                depressed
                block
                color="secondary"
                :to="{ name:'login' }"
            >
                {{ $t('GENERIC.ACTIONS.BTN_GOTO_LOGIN', { brand : whiteLabel.brand_name }) }}
            </v-btn>
            <v-form
                ref="form"
                lazy-validation
                class="full-width text-center"
                @submit.prevent="processForm"
                :hidden="success"
            >

                <v-text-field
                    id="password"
                    v-model="password"
                    type="password"
                    name="_password"
                    variant="underlined"
                    :label="$t('GENERIC.FORM.LABELS.PASSWORD')"
                    required
                    class="public-field"
                    @blur="v$.password.$touch()"
                    :error-messages="passwordErrors"
                    :error="v$.password.$error"
                />

                <v-text-field
                    id="password_repeat"
                    v-model="passwordRepeat"
                    variant="underlined"
                    type="password"
                    name="_password_repeat"
                    :label="$t('GENERIC.FORM.LABELS.PASSWORD_AGAIN')"
                    required
                    class="public-field"
                    @blur="v$.passwordRepeat.$touch()"
                    :error-messages="passwordRepeatErrors"
                    :error="v$.passwordRepeat.$error"
                />

                <v-btn
                    class="mt-5 text-uppercase"
                    type="submit"
                    depressed
                    block
                    color="secondary"
                    :loading="loading"
                    :hidden="success"
                >
                    {{ $t('GENERIC.ACTIONS.SAVE_PASSWORD') }}
                </v-btn>
            </v-form>
        </template>
    </dx-login-pass-layout>
</template>

<script>

import useVuelidate from '@vuelidate/core';
import DxLoginPassLayout from '@/components/Forms/Login/DxLoginPassLayout';
import DxGlobalFormAlert from '@@/shared/components/forms/DxGlobalFormAlert.vue';

import { required, helpers } from '@vuelidate/validators';

export default {
    name: 'PasswordResetRequest',
    setup () {
        return { v$: useVuelidate() }
    },
    components: {
        DxLoginPassLayout,
        DxGlobalFormAlert
    },
    inject: ['whiteLabel'],
    data: () => ({
        loading: false,
        success: false,
        info: '',
        resetError: '',
        password: '',
        passwordRepeat: ''
    }),
    validations() {
        return {
            password: { required: helpers.withMessage('GENERIC.FORM.ERRORS.REQUIRED', required) },
            passwordRepeat: { required: helpers.withMessage('GENERIC.FORM.ERRORS.REQUIRED', required) }
        }
    },
    watch: {
        password() {
            this.resetError = '';
        },
        passwordRepeat() {
            this.resetError = '';
        }
    },
    methods: {
        processForm() {
            this.v$.$validate().then(valid => {
                if (valid) {
                    this.loading = true;
                    this.$http.post('', { password: this.password, password_repeat: this.passwordRepeat })
                        .then(response => {
                            this.success = true;
                            this.info = response.data.info;
                        })
                        .catch(error => {
                            this.resetError = error.response.data.error;
                            this.success = false;
                            this.loading = false;
                        });
                }
            });
        }
    },
    computed: {
        passwordErrors() {
            return (this.v$.password.$errors.length) ? this.$t(this.v$.password.$errors[0].$message) : [];
        },
        passwordRepeatErrors() {
            return (this.v$.passwordRepeat.$errors.length) ? this.$t(this.v$.passwordRepeat.$errors[0].$message) : [];
        }
    }
}
</script>
