import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-56cd638d"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "d-flex justify-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dx_global_form_alert = _resolveComponent("dx-global-form-alert")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_dx_login_pass_layout = _resolveComponent("dx-login-pass-layout")!

  return (_openBlock(), _createBlock(_component_dx_login_pass_layout, {
    title: _ctx.$t('LOGIN.WELCOME_TITLE', { brand: _ctx.whiteLabel.brand_name }),
    subtitle: _ctx.$t('LOGIN.LOGIN_TEXT')
  }, {
    body: _withCtx(() => [
      _createVNode(_component_v_form, {
        id: "form",
        ref: "form",
        action: "/login_check",
        method: "POST",
        onSubmit: _withModifiers(_ctx.processForm, ["prevent"])
      }, {
        default: _withCtx(() => [
          (_ctx.displayLoginError || _ctx.displayInfo)
            ? (_openBlock(), _createBlock(_component_dx_global_form_alert, {
                key: 0,
                message: _ctx.displayLoginError ? _ctx.error : _ctx.info,
                type: _ctx.displayLoginError ? 'error' : 'success'
              }, null, 8 /* PROPS */, ["message", "type"]))
            : _createCommentVNode("v-if", true),
          _withDirectives(_createElementVNode("div", null, [
            _createVNode(_component_v_text_field, {
              id: "username",
              modelValue: _ctx.username,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.username) = $event)),
              name: "_username",
              label: _ctx.$t('GENERIC.FORM.LABELS.EMAIL'),
              variant: "underlined",
              class: "public-field",
              onKeyup: _ctx.hideAlerts,
              onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.v$.username.$touch())),
              error: _ctx.v$.username.$error || _ctx.displayLoginError,
              "error-messages": _ctx.userNameErrors
            }, null, 8 /* PROPS */, ["modelValue", "label", "onKeyup", "error", "error-messages"])
          ], 512 /* NEED_PATCH */), [
            [_vShow, _ctx.hidePassword]
          ]),
          (!_ctx.hidePassword)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_v_btn, {
                  title: _ctx.$t('GENERIC.FORM.ACTIONS.EDIT'),
                  class: "back text-lowercase mb-2",
                  ripple: false,
                  variant: "outlined",
                  size: "small",
                  "prepend-icon": "fa-arrow-left",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.hidePassword = !_ctx.hidePassword, _ctx.displayInfo = false, _ctx.displayLoginError = false))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.username), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["title"])
              ]))
            : _createCommentVNode("v-if", true),
          (!_ctx.hidePassword)
            ? (_openBlock(), _createBlock(_component_v_text_field, {
                key: 2,
                id: "password",
                variant: "underlined",
                modelValue: _ctx.password,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.password) = $event)),
                hidden: _ctx.hidePassword,
                type: "password",
                name: "_password",
                label: _ctx.$t('GENERIC.FORM.LABELS.PASSWORD'),
                class: "public-field",
                required: "",
                "error-messages": _ctx.passwordErrors,
                error: _ctx.v$.password.$error || _ctx.displayLoginError,
                onBlur: _cache[4] || (_cache[4] = ($event: any) => (_ctx.v$.password.$touch())),
                onKeyup: _ctx.hideAlerts
              }, null, 8 /* PROPS */, ["modelValue", "hidden", "label", "error-messages", "error", "onKeyup"]))
            : _createCommentVNode("v-if", true),
          _createVNode(_component_router_link, {
            class: "float-right",
            to: { name:'password-reset-request', params: { emailForgotPassWord: _ctx.emailForgotPassWord } }
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('GENERIC.FORM.LABELS.FORGOT_PASSWORD')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["to"]),
          _createVNode(_component_v_btn, {
            class: "mt-13 text-uppercase",
            type: "submit",
            disabled: _ctx.loading,
            loading: _ctx.loading,
            depressed: "",
            block: "",
            color: "secondary",
            onClick: _ctx.processForm
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.hidePassword ? _ctx.$t('GENERIC.ACTIONS.NEXT') : _ctx.$t('GENERIC.ACTIONS.CONNECT')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["disabled", "loading", "onClick"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onSubmit"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title", "subtitle"]))
}