import { uid } from 'uid';

export const SET_USER = (state, payload) => {
    state.user = payload;
};

export const SET_LANG = (state, payload) => {
    state.lang = payload;
};

export const SET_SHORCUTS = (state, payload) => {
    state.shortcutsList = payload;
};

export const ADD_SHORTCUTS = (state, shortcut) => {
    state.shortcutsList.push(shortcut);
};

export const SET_GLOBAL_TRIGGER = (state, payload) => {
    state.globalTrigger = payload;
};

export const ADD_NOTIFICATION = (state, payload) => {
    const notification = { id: uid(), show: true, ...payload };
    if (state.notifications.findIndex(notif => notif.text === payload.text) === -1) {
        state.notifications.push(notification);
    }
};

export const REMOVE_NOTIFICATION = (state) => {
    const index = state.notifications.findIndex(notification => !notification.show);
    if (index !== -1) {
        state.notifications.splice(index, 1);
    }
};

export const SET_LOGIN_EMAIL = (state, payload) => {
    state.loginEmail = payload;
};