import { createI18n } from 'vue-i18n'
import { getLang } from '@@/shared/utils.vue';
import defaultLanguage from './en.json';

export const i18n = createI18n({
    allowComposition: true,
    legacy: false,
    locale: 'en',
    fallbackLocale: 'en',
    messages: { en: defaultLanguage }
});

const loadedLanguages = ['en'];

function setI18nLanguage(lang) {
    i18n.global.locale.value = lang
    return lang
}

export function loadLanguageAsync(language) {
    const lang = language ? language : getLang();
    if (i18n.locale === lang && loadedLanguages.includes(lang)) {
        return Promise.resolve(setI18nLanguage(lang))
    }

    return import(/* webpackChunkName: "lang-public-[request]" */ `./${lang}.json`).then(
        messages => {
                i18n.global.setLocaleMessage(lang, messages.default);
                if(!loadedLanguages.includes(lang)) loadedLanguages.push(lang);

                return setI18nLanguage(lang)
            }
    )
}
