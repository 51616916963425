import translate from './translate';
import transChoice from './transChoice';

export default {
    install: (app) => {
        app.mixin({
            beforeCreate() {
                this.$filters = {
                    trans: translate,
                    translate,
                    transChoice
                }
            }
        });
    }
}