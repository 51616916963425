import { resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_dx_notications = _resolveComponent("dx-notications")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, {
    id: 'dx' + Date.now(),
    class: _normalizeClass(_ctx.globalStyles)
  }, {
    default: _withCtx(() => [
      (_ctx.init.componentName !== 'singlePage')
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.componentInstance), _normalizeProps(_mergeProps({ key: 0 }, _ctx.currentProperties)), null, 16 /* FULL_PROPS */))
        : (_openBlock(), _createBlock(_component_router_view, { key: 1 })),
      _createVNode(_component_dx_notications)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["id", "class"]))
}