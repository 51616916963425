import axios from 'axios';

export const HTTP = axios.create({
    headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'X-Requested-With': 'XMLHttpRequest'
    }
});

export const route = ({ name, params = null, absolute = false }) => {
    if (!window.Routing) {
        throw new Error('No rooting dependency')
    }

    return window.Routing.generate.apply(window.Routing, [name, params, absolute]);
};