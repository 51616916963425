import browserUpdate from 'browser-update'

let msgmore;
if (navigator.userAgent.indexOf('Trident') > -1) {
    msgmore = 'Update your browser, it\'s not supported on this site'
} else {
    msgmore = 'Update your browser for more security, speed and the best experience on this site.'
}
// Will show a banner if the user's browser is outdated, asking him to
// update or change his browser
export default function showBrowserUpdate() {
    browserUpdate({
        // Indicate here the minimal browser version supported by the application
        // (i = internet explorer, f = firefox, c = chrome, o = opera, s = safari, e = edge)
        required: {
            i: 12, e: 108, f: 91, c: 109, s: 15, o: 94
        },
        text: {
            msg: 'Your web browser ({brow_name}) is out of date.',
            msgmore,
            bupdate: 'Update browser',
            bignore: 'Ignore',
            remind: 'You will be reminded in {days} days.'
        },
        // Indicate after how many hour the message reappear
        reminder: 24 * 7,
        no_permanent_hide: true,
        container: document.body,
        onshow(infos) {
            if (infos.browser.engine === 'i') {
                document.getElementById('buorg').className = 'buorg ie';
            }
        }
    })
}
