import { createApp } from 'vue';
import store from './store/store';
import router from './routes/routes';
import vuetify from './plugins/vuetify';
import { i18n } from './locales';
import filters from "../Vue/shared/filters";
import { pluginUtils } from "../Vue/shared/utils.vue";
import DxAsyncLoadError from "../Vue/shared/components/DxAsyncLoadError";
import DxAsyncLoading from "../Vue/shared/components/DxAsyncLoading";
import { HTTP } from "../Vue/http-common";

export default class AppManager {
    constructor(App) {
        this.appCollection = [];
        this.App = App;
    }

    initAppVue({ config, domAppRoot }) {
        const app = createApp(this.App, {
            init: config
        })
            .use(router)
            .use(store)
            .use(vuetify)
            .use(filters)
            .use(pluginUtils)
            .use(i18n)
            .component('DxError', DxAsyncLoadError)
            .component('DxLoading', DxAsyncLoading)

        app.config.devtools = process.env.NODE_ENV === 'development';
        app.config.globalProperties.$http = { ...HTTP };

        app.mount(domAppRoot);
        this.appCollection.push(app);
    }

    domParse(domTarget = document) {
        [...domTarget.querySelectorAll('[data-appvue]')].forEach(domAppRoot => {
            this.initAppVue({
                config: JSON.parse(domAppRoot.getAttribute('data-appvue')),
                domAppRoot
            });
        });
    }

    trigger({ id, triggerName, detail = {} }) {
        this.appCollection.find(app => app.id === id).$store.commit('SET_GLOBAL_TRIGGER', { triggerName, detail });
    }
}