import { HTTP } from './http-common';

export default () => {
    document.querySelector('body').classList.add('ie');
    if(window.location.href.indexOf('cgu') > -1) {
        document.getElementById('iecgu').style.display = 'flex';
        var lang = document.querySelector('html').getAttribute('lang');

        var params = { params: { locale: lang } };

        HTTP.get('/cgu/ajax', params)
            .then(response => {
                var cgutext = '';
                response.data.availableLocales.forEach(function (cguLocale) {
                    if(cguLocale.value === lang) {
                        cgutext = cguLocale.text;
                    }
                });
                document.querySelector('.cgu-validate').innerHTML = cgutext;
            });
    } else {
        document.getElementById('ielogin').style.display = 'flex';

        var form = document.querySelector('#ielogin #form');
        var login = document.querySelector('#username');
        var pass = document.querySelector('#password');
        var passwordWapper = document.querySelector('#passwordWapper');

        form.addEventListener('submit', event => {
            if(passwordWapper.style.display === 'none') {
                event.preventDefault();
                HTTP.post('/login_method', { username: login.value })
                    .then(response => {
                        var data = response.data;
                        var method = data.method;
                        if (method === 'sso') {
                            window.location = data.url_sso;
                        } else {
                            form.querySelector('button').classList.add('connect');
                            passwordWapper.style.display = 'block';
                            pass.required = true;
                            pass.focus();
                        }
                })
            }
        })
    }

}