<template>
    <vue-cookie-accept-decline
        element-id="cookieBanner"
        class="v-card v-sheet theme&#45;&#45;light rounded white"
        :disable-decline="true"
        type="bar"
    >
        <template #message>
            <h2>{{ $filters.translate('TK.COMMON.COOKIE.TITLE') }}</h2>
            <p>{{ $filters.translate('TK.COMMON.COOKIE.MESSAGE') }}</p>
        </template>
        <template #acceptContent>
            {{ $filters.translate('TK.COMMON.COOKIE.BUTTON') }}
        </template>
    </vue-cookie-accept-decline>
</template>

<script>
import VueCookieAcceptDecline from 'vue-cookie-accept-decline';
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css';

export default {
    name: 'DxCookieBanner',
    components: {
        VueCookieAcceptDecline
    }
}
</script>
<style lang="scss" scoped>

#cookieBanner {
    max-width: none;
    position: fixed;
    display: flex;
    width: 80%;
    max-height: 200px;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -10px);
    padding: 30px 50px;
    border: none;
    z-index: 2;

    h2 {
        font-size: 16px;
        font-weight: normal;
        margin-bottom: 1em;
        color: black;
    }

    p {
        font-size: 14px;
        margin-bottom: 0 !important;
        padding-right: 1.2em;
        color: rgb(var(--v-theme-base-darken-4));
    }

    :deep() {
        .cookie__bar__wrap {
            display: flex;
            align-items: center;
        }

        .cookie__bar__buttons__button--accept {
            @extend .v-btn !optional;
            min-width: 150px;
            min-height: 36px;
            font-size: 12px;
            text-transform: uppercase !important;
            background: rgb(var(--v-theme-secondary)) !important;
            color: white;
            border-radius: 5px;
            &:hover {
                background: #71A3D5 !important;
            }
            &:focus {
                outline: none;
            }
        }

        .cookie__bar__content {
            max-height: none;
            height: 100%;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    @media (max-width: 1200px) {
        width: 90%;
        padding: 20px 35px;
        p {
            width: 90%;
        }
    }

    @media (max-width: 800px) {
        width: 100%;
        left: 0;
        transform: translate(0 ,-10px);
        padding: 20px 35px 10px;

        h2 {
            margin-bottom: 0.5em;
        }

        :deep() {
            .cookie__bar__content {
                display: block;
                height: 150px;
                margin-bottom: 0;
            }
        }
    }
}
</style>