<template>
    <div>
        <v-card elevation="1">
            <v-card-title style="padding: 0; margin: 0">
                <v-alert
                    v-if="error"
                    style="width: 100%; height: 100%"
                    type="warning"
                    variant="tonal"
                >
                    {{ $t('PUBLIC_LINK.WARNING') }}
                </v-alert>
                <v-alert
                    v-if="info"
                    style="width: 100%; height: 100%"
                    type="success"
                    variant="tonal"
                >
                    {{ $t('PUBLIC_LINK.SUCCESS') }}
                </v-alert>
            </v-card-title>

            <v-card-text>
                <div
                    v-if="info"
                    class="cardtext"
                >
                    {{ info }}
                </div>
                <div
                    v-if="error"
                    class="cardtext"
                    v-html="error"
                />

                <v-card-actions v-if="submissionData.button_url">
                    <v-btn
                        :href="submissionData.button_url"
                        class="cardaction-link"
                        variant="text"
                    >
                        {{ submissionData.button_text }}
                    </v-btn>
                </v-card-actions>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>

export default {
    name: 'PublicLinkDisplay',
    props: {
        error: {
            type: String,
            required: true
        },
        info: {
            type: String,
            required: true
        },
        componentData: {
            type: String,
            required: true
        }
    },
    computed: {
        submissionData() {
            const data = JSON.parse(this.componentData);
            return {
                button_url: data.button_url,
                button_text: data.button_text
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.cardtext {
    font-size: 1.5em;
    padding: 35px 35px 40px;
}

.cardaction-link {
    font-weight: bold;
    color: map-get($color1, bg);
}
</style>
