<template>
    <dx-login-pass-layout
        :title="$t('FORGOTTEN_PASSWORD.RESET_PASSWORD_REQUEST_TITLE')"
        :subtitle="$t('FORGOTTEN_PASSWORD.RESET_PASSWORD_REQUEST_TEXT')"
    >
        <template v-slot:body>
            <v-form
                ref="form"
                lazy-validation
                @submit.prevent="processForm"
                class="full-width"
            >
                <dx-global-form-alert
                    v-if="error || info"
                    :message="error ? error : info"
                    :type="error ? 'error' : 'success'"
                />

                <v-text-field
                    id="username"
                    v-model="username"
                    variant="underlined"
                    name="_username"
                    :label="$t('GENERIC.FORM.LABELS.EMAIL')"
                    class="public-field"
                    @blur="v$.username.$touch()"
                    :error="v$.username.$error"
                    :error-messages="userNameErrors"
                />

                <v-btn
                    type="submit"
                    class="mt-13 text-uppercase"
                    depressed
                    block
                    color="secondary"
                    :loading="loading"
                    :hidden="emailSent"
                >
                    {{ $t('GENERIC.ACTIONS.RESET_PASSWORD') }}
                </v-btn>
            </v-form>
        </template>
    </dx-login-pass-layout>
</template>

<script>

import useVuelidate from '@vuelidate/core';
import DxLoginPassLayout from '@/components/Forms/Login/DxLoginPassLayout';
import DxGlobalFormAlert from '@@/shared/components/forms/DxGlobalFormAlert.vue';

import { required, helpers, email } from '@vuelidate/validators';

export default {
    name: 'PasswordResetRequest',
    setup () {
        return { v$: useVuelidate() }
    },
    components: {
        DxLoginPassLayout,
        DxGlobalFormAlert
    },
    data: () => ({
        loading: false,
        emailSent: false,
        info: '',
        error: '',
        username: ''
    }),
    watch: {
        username() {
            this.error = '';
        }
    },
    validations() {
        return {
            username: {
                required: helpers.withMessage('GENERIC.FORM.ERRORS.REQUIRED', required),
                email: helpers.withMessage('GENERIC.FORM.ERRORS.EMAIL', email)
            }
        }
    },
    mounted() {
        this.username = this.$store.state.loginEmail;
    },
    methods: {
        processForm() {
            this.error = '';
            if (!this.emailSent) {
                this.v$.$validate().then(valid => {
                    if (valid === true) {
                        this.loading = true;
                        // SEEK FOR LOGIN METHOD (PASSWORD OR SSO
                        this.$http.post('/login-reset-password', { username: this.username })
                            .then(() => {
                                this.info = this.$t('FORGOTTEN_PASSWORD.EMAILSENT');
                                this.emailSent = true;
                                this.loading = false;
                            })
                            .catch(error => {
                                this.error = error.response.data;
                                this.loading = false;
                            });
                    }
                });
            }
        }
    },
    computed: {
        userNameErrors() {
            return (this.v$.username.$errors.length) ? this.$t(this.v$.username.$errors[0].$message) : [];
        }
    }
}
</script>
