import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import { aliases, fa } from 'vuetify/iconsets/fa4';
import json from '../../Vue/plugins/colors.json';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

const dir = document.querySelector('html').getAttribute('dir');

const daxiumTheme = {
    dark: false,
    colors: {
        ...json.themes.light
    }
}

export default createVuetify({
    rtl: dir === 'rtl',
    components,
    directives,
    theme: {
        defaultTheme: 'daxiumTheme',
       themes: {
           daxiumTheme
       },
        variations: {
            colors: Object.keys(daxiumTheme.colors),
            lighten: 5,
            darken: 4,
        }
    },
    icons: {
        defaultSet: 'fa',
        aliases,
        sets: {
            fa
        }
    }
});
