<template>
    <v-alert
        outlined
        type="warning"
        prominent
        icon="fa-exclamation"
        border="left"
    >
        Module loading fail. Try again or contact support
    </v-alert>
</template>

<style lang="scss" scoped>
    .v-alert {
        clear: both;
        .v-alert__wrapper {
            width: 100%;
        }
    }
</style>