<template>
    <transition-group
        tag="div"
        name="notif"
    >
        <v-snackbar
            v-for="(notif, index) in notificationsGetter"
            :key="notif.id"
            :value="notif.show"
            :top="true"
            :right="true"
            :color="notif.type"
            :timeout="transitionDuration"
            :style="{ transform: `translateY(${index*100}%)` }"
            @input="inputEvent($event, notif)"
        >
            <v-icon class="mr-2">
                {{ setIcon(notif.type) }}
            </v-icon> {{ setText(notif.text) }}
            <template #action="{ attrs }">
                <v-btn
                    color="white"
                    icon
                    v-bind="attrs"
                    @click="close(notif)"
                >
                    <v-icon small>
                        fa-times
                    </v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </transition-group>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
    name: 'DxNotications',
    data: () => (
        {
            transitionDuration: 4000
        }
    ),
    computed: {
        ...mapGetters([
            'notificationsGetter'
        ])
    },
    methods: {
        ...mapMutations([
            'REMOVE_NOTIFICATION'
        ]),
        close(value) {
            const notif = value;
            notif.show = false;
            setTimeout(() => {
                this.REMOVE_NOTIFICATION();
            }, this.transitionDuration);
        },
        inputEvent(event, notif) {
            if (!event) {
                this.close(notif);
            }
        },
        setIcon(type) {
            switch (type) {
            case 'error':
                return 'fa-exclamation-circle';
            case 'success':
                return 'fa-check';
            case 'info':
                return 'fa-info-circle';
            case 'warning':
                return 'fa-exclamation-triangle';
            default:
                return ''
            }
        },
        setText(text) {
            if (text.indexOf('TK.') > -1) {
                return this.$filters.translate(text);
            }
            return text;
        }
    }
}
</script>

<style lang="scss" scoped>
    :deep(.v-snack) {
        opacity: 0.4;
        height: auto;
        top: 50px;

        .v-snack__content {
            font-size: 12px;
            font-weight: 500;
        }

        &:hover {
            opacity: 1;
        }

        .v-snack__wrapper {
            min-width: 300px;
        }
    }

    .notif-enter-active, .notif-leave-active {
        transition: all 1.5s;
    }

    .notif-enter, .notif-leave-to {
        opacity: 0;
    }
</style>
